/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Culqi from "@components/sharedComponents/customersLogos/logos/Culqui.svg"
import Auna from "@components/sharedComponents/customersLogos/logos/Auna.svg"
import Cobra from "@components/sharedComponents/customersLogos/logos/Cobra.svg"
import Astara from "@components/sharedComponents/customersLogos/logos/Astara.svg"
import Ultralon from "@components/sharedComponents/customersLogos/logos/Ultralon.svg"
import Chiolecca from "@components/sharedComponents/customersLogos/logos/Chiolecca.svg"
import Proempresa from "@components/sharedComponents/customersLogos/logos/Proempresa.svg"
import Cepeban from "@components/sharedComponents/customersLogos/logos/Cepeban.svg"
import Unifef from "@components/sharedComponents/customersLogos/logos/Unifef.svg"
import Cafae from "@components/sharedComponents/customersLogos/logos/Cafae.svg"
import Escampa from "@components/sharedComponents/customersLogos/logos/Escampa.svg"
import LaMatier from "@components/sharedComponents/customersLogos/logos/LaMatier.svg"

//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/chatbots-para-whatsapp.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/automatizacion-whatsapp.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/confirmacion-asistencia-por-whatsapp.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/whatsapp-vs-email.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/whatsapp-sms.jpg"

// logos testimonios
import AstaraTes from "@components/sharedComponents/testimonies/images/logos/astara.svg"
import ProempresaTes from "@components/sharedComponents/testimonies/images/logos/proempresa.svg"

//foto testimonio desktop
import astaraDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/astara.png"

//foto testimonio desktop
import astaraMobile from "@components/sharedComponents/testimonies/images/fotosMobile/astara.png"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageFunctionalities/pageWhatsappMasivos/images/accordionLeft/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageFunctionalities/pageWhatsappMasivos/images/accordionLeft/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageFunctionalities/pageWhatsappMasivos/images/accordionLeft/imageThree.webp"
import ImageAccordionLeftFour from "@components/pageFunctionalities/pageWhatsappMasivos/images/accordionLeft/imageFour.webp"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageMasivos.jpg"

import { TitleUnderlineWhatsappMasivosOne, TitleUnderlineWhatsappMasivosTwo } from "@components/pageFunctionalities/components/intro/titleUnderlineWhatsappMasivos"
import imageStartWhatsappMasivos from "@components/pageFunctionalities/components/intro/images/imageStartWhatsappMasivos.webp"

import ImageVerticalOne from "@components/pageFunctionalities/pageWhatsappMasivos/images/imagesNavbarVertical/imageOne.webp"
import ImageVerticalTwo from "@components/pageFunctionalities/pageWhatsappMasivos/images/imagesNavbarVertical/imageTwo.webp"
import ImageVerticalThree from "@components/pageFunctionalities/pageWhatsappMasivos/images/imagesNavbarVertical/imageThree.webp"
import ImageVerticalFour from "@components/pageFunctionalities/pageWhatsappMasivos/images/imagesNavbarVertical/imageFour.webp"

//image indrustias
import banca from "@components/pageFunctionalities/pageWhatsappMasivos/industries/images/banca.png"
import salud from "@components/pageFunctionalities/pageWhatsappMasivos/industries/images/salud.png"
import ecommerce from "@components/pageFunctionalities/pageWhatsappMasivos/industries/images/ecommerce.png"
import educacion from "@components/pageFunctionalities/pageWhatsappMasivos/industries/images/educacion.png"
import concesionaria from "@components/pageFunctionalities/pageWhatsappMasivos/industries/images/concesionaria.png"
import inmobiliaria from "@components/pageFunctionalities/pageWhatsappMasivos/industries/images/inmobiliaria.png"

const titleStart = (
  <p>
    Envía mensajes masivos por WhatsApp y mejora la <TitleUnderlineWhatsappMasivosOne underline="conversión" /> con tus contactos

  </p>
)
let data = {
  start: {
    title: titleStart,
    textbody: (
      <p>
        Comunícate con toda tu base de clientes de WhatsApp al mismo tiempo de manera personalizada en diferentes procesos como ofertas, recordatorios o alertas importantes.
        <br /><br />
        Integra WhatsApp con herramientas externas y utiliza la inteligencia artificial para crear mensajes personalizados.
      </p>
    ),
    image: imageStartWhatsappMasivos,
    textbutton: "Habla con un experto",
    link: "/hablemos/",
  },
  customersDesktop: [
    Culqi,
    Auna,
    Cobra,
    Astara,
    Ultralon,
    Chiolecca,
    Proempresa,
    Cepeban,
    Unifef,
    Cafae,
    Escampa,
    LaMatier
  ],
  customersTablet: {
    arrayOne: [Culqi, Auna, Cobra, Astara, Ultralon, Chiolecca],
    arrayTwo: [Proempresa, Cepeban, Unifef, Cafae, Escampa, LaMatier],
  },
  articles: [
    {
      image: article1,
      title: "5 razones para implementar chatbots para WhatsApp en tu negocio",
      textbody:
        "Conoce qué son los chatbots para WhatsApp y empieza a optimizar la atención al cliente digital y las ventas de tu negocio.",
      link: "https://beexcc.com/blog/chatbots-para-whatsapp/",
      card: true,
    },
    {
      image: article2,
      title: "¿Cómo implementar la automatización de WhatsApp en simples pasos?",
      textbody:
        "Descubre qué es la automatización de WhatsApp y cómo implementarlo con estos pasos y consejos. ¡ENTRA AQUÍ y eleva la productividad de tus equipos!",
      link: "https://beexcc.com/blog/automatizacion-whatsapp/",
      card: true,
    },
    {
      image: article3,
      title: "Confirmación de asistencia por WhatsApp: organiza tus eventos con estos pasos y consejos",
      textbody:
        "Descubre qué es la confirmación de asistencia por WhatsApp. ¡ENTRA AQUÍ y prepara con éxito tus eventos, desde citas médicas hasta capacitaciones!",
      link: "https://beexcc.com/blog/confirmacion-asistencia-por-whatsapp/",
    },
    {
      image: article4,
      title: "WhatsApp vs Email marketing: ¿Cuál tiene un mayor impacto?",
      textbody:
        "Creamos un WhatsApp vs Email para conocer sus fortalezas y debilidades de cada uno y así sepas cuál es el ideal para tu empresa.",
      link: "https://beexcc.com/blog/whatsapp-vs-email/",
    },
    {
      image: article5,
      title: "WhatsApp vs SMS: ¿Cuál es más efectivo para tu empresa?",
      textbody:
        "Creamos un WhatsApp vs SMS para conocer sus fortalezas y debilidades de cada uno y así sepas cuál es el ideal para tu empresa.",
      link: "https://beexcc.com/blog/whatsapp-sms/",
    },
  ],
  contacts: {
    title: "Empieza a conectar con todos tus clientes por WhatsApp de una forma personalizada",
    textbody: "Déjanos contarte como puedes mejorar increíblemente la comunicación y fidelización de tu negocio o empresa con nuestra solución.",
    href: "/hablemos/",
    btn: "Quiero una asesoría gratuita",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  testimonies: [
    {
      image: AstaraTes,
      title:
        "Astara triplicó sus ventas digitales con WhatsApp Business API",
        link: "/casos-de-exito/astara/",
      industrie: "Concesionarias",
      backgroundIndustrie: "#231E36",
      backgroundFondo: "#231E3633",
      metrics: [
        {
          metric: "x3",
          descripcion: "aumento de ventas digitales",
          color: "#FFB800",
        },
        {
          metric: "93%",
          descripcion: "menos esfuerzo en remarketing",
          color: "#5831D8",
        },
      ],
    },
    {
      image: ProempresaTes,
      title:
          "ProEmpresa aumentó en un 30% sus ventas con campañas masivas en WhatsApp",
      link: "/casos-de-exito/proempresa/",
      industrie: "Finanzas",
      backgroundIndustrie: "#348F49",
      backgroundFondo: "#348F4933",
      metrics: [
          {
              metric: "+30%",
              descripcion: "aumento de ventas digitales",
              color: "#FFB800",
          },
          {
              metric: "+20%",
              descripcion: "mejoró su CSAT",
              color: "#5831D8",
          },
      ],
  },
  ],
  accordionRight: [

    {
      title: "Integración con Base de Datos",
      description: "<p>Integra WhatsApp con herramientas externas como CRM o ERP para enviar mensajes altamente personalizados y relevantes, asegurando que cada comunicación sea específica para el historial, preferencias, y comportamiento de compra del cliente.</p>",
      image: ImageAccordionLeftTwo
    },
    {
      title: "Automatización con Workflows",
      description: "<p>Establece secuencias de mensajes para diferentes propósitos, como seguimiento post-venta, recordatorios de renovación, o series de bienvenida para nuevos clientes. Estos workflows pueden activarse basándose en acciones específicas del cliente o hitos.</p>",
      image: ImageAccordionLeftThree,
    },
    {
      title: "Recuperación de Clientes",
      description: "<p>Conecta con aquellos que han mostrado disminución en la interacción o que no han realizado compras recientemente. A través de mensajes personalizados basados en compras anteriores o intereses mostrados, puedes ofrecer ofertas especiales, o descuentos que incentiven su regreso.</p>",
      image: ImageAccordionLeftFour
    },
    {
      title: "Crea Workflows",
      description: "<p>Crea respuestas únicas para cada usuario utilizando inteligencia artificial. Crea chatbots potenciados por IA con ChatGPT y clasifica y segmenta a tus clientes por intensiones de compra en base a las conversaciones que surgen en una atención.</p>",
      image: ImageAccordionLeftOne
    },
  ],
  bannerVideo: {
    title: "¿Cómo crear una campaña de mensajes masivos en WhatsApp?",
    description: "<p>Los envíos de mensajes masivos por WhatsApp para empresas abren un abanico de oportunidades para mejorar la comunicación con los clientes, la eficiencia operativa y el marketing. <br /><br />Nuestro Product Manager, Jheyson Portocarrero, nos enseña paso a paso cómo crear una campaña de mensajes masivos en WhatsApp para promocionar tu empresa.</p>",
    image: imageVideo
  },
  navbarVerticalRight: [
    {
      tab: "1",
      title: "Notificaciones Personalizadas",
      text: (
        <p>
          Envía mensajes personalizados a gran escala sobre actualizaciones de productos, recordatorios de citas o eventos especiales. La personalización puede basarse en el comportamiento del cliente, preferencias y datos históricos para aumentar la relevancia y el compromiso.
        </p>
      ),
      image: ImageVerticalOne,
      active: "container-navbar-vertical-right-navbar-menu-button-active",
    },
    {
      tab: "2",
      title: "Alertas de Estado de Pedido",
      text: (
        <p>
          Mantén a los clientes informados sobre el estado de sus pedidos, desde la confirmación hasta la entrega. Mejora la experiencia del cliente, y reduce las consultas al servicio de atención al cliente.
        </p>
      ),
      image: ImageVerticalTwo,
    },
  ],
  navbarVerticalLeft: [
    {
      tab: "3",
      title: "Marketing y Promociones",
      text: (
        <p>
          Lanzar campañas de marketing dirigidas, promociones exclusivas, o descuentos a segmentos específicos de clientes. Los mensajes pueden incluir imágenes, videos, y enlaces a landingpage para enriquecer la experiencia del usuario.
        </p>
      ),
      image: ImageVerticalThree,
      active: "container-navbar-vertical-left-navbar-menu-button-active",
    },
    {
      tab: "4",
      title: "Encuestas y Recolección de Feedback",
      text: (
        <p>
          Envía encuestas rápidas o solicitar feedback después de una compra o interacción con el servicio al cliente. Esto no solo proporciona insights valiosos para la mejora continua, sino que también hace sentir a los clientes que su opinión es valorada.
        </p>
      ),
      image: ImageVerticalFour,
    },
  ],
  navbarVerticalSilder: [
    {
      tab: "1",
      title: "Notificaciones Personalizadas",
      text: (
        <p>
          Envía mensajes personalizados a gran escala sobre actualizaciones de productos, recordatorios de citas o eventos especiales. La personalización puede basarse en el comportamiento del cliente, preferencias y datos históricos para aumentar la relevancia y el compromiso.
        </p>
      ),
      image: ImageVerticalThree,
      active: "container-navbar-vertical-right-navbar-menu-button-active",
    },
    {
      tab: "2",
      title: "Alertas de Estado de Pedido",
      text: (
        <p>
          Mantén a los clientes informados sobre el estado de sus pedidos, desde la confirmación hasta la entrega. Mejora la experiencia del cliente, y reduce las consultas al servicio de atención al cliente.
        </p>
      ),
      image: ImageVerticalTwo,
    },
    {
      tab: "3",
      title: "Marketing y Promociones",
      text: (
        <p>
          Lanzar campañas de marketing dirigidas, promociones exclusivas, o descuentos a segmentos específicos de clientes. Los mensajes pueden incluir imágenes, videos, y enlaces a landingpage para enriquecer la experiencia del usuario.
        </p>
      ),
      image: ImageVerticalFour,
    },
    {
      tab: "4",
      title: "Encuestas y Recolección de Feedback",
      text: (
        <p>
          Envía encuestas rápidas o solicitar feedback después de una compra o interacción con el servicio al cliente. Esto no solo proporciona insights valiosos para la mejora continua, sino que también hace sentir a los clientes que su opinión es valorada.
        </p>
      ),
      image: ImageVerticalOne,
      active: "container-navbar-vertical-left-navbar-menu-button-active",
    },
  ],
  industries: [
    {
      tab: "01. E-commerce",
      title: "Mensajes masivos por WhatsApp para E-commerce y Retail",
      text: (
        <p>
          <br />
          Notifica a los clientes sobre ofertas especiales, nuevos lanzamientos de productos y actualizaciones de estado de pedidos. Personaliza estas notificaciones, haciendo recomendaciones de productos basadas en compras anteriores o intereses mostrados.
          <br /> <br /> <br />
        </p>
      ),
      image: ecommerce,
      active: "container-industries-channels-navbar-menu-button-active",
      link: "/hablemos/",
      textLink: "Conoce WhatsApp masivo para E-commerce",
    },
    {
      tab: "02. Banca y finanzas",
      title: "Mensajes masivos por WhatsApp para Banca, Seguros, Financieras y más",
      text: (
        <p>
          <br />
          Envía alertas de seguridad, confirmaciones de transacciones, y actualizaciones de productos financieros. Facilita los recordatorios de pago y ofrecer asistencia en tiempo real, mejorando la relación cliente-banco y aumentando la confianza en los servicios ofrecidos.
          <br /> <br /> <br />
        </p>
      ),
      image: banca,
      link: "/hablemos/",
      textLink: "Conoce WhatsApp masivo para Banca y Finanzas",
    },
    {
      tab: "03. Educación",
      title: "Mensajes masivos por WhatsApp para Universidades, Institutos y Colegios",
      text: (
        <p>
          <br />
          Envía recordatorios de fechas importantes, como inscripciones, fechas de exámenes o eventos escolares. Permite una comunicación directa con estudiantes y padres, ofreciendo actualizaciones curriculares, material de estudio adicional y encuestas de satisfacción.
          <br /> <br /><br />
        </p>
      ),
      image: educacion,
      link: "/hablemos/",
      textLink: "Conoce WhatsApp masivo para Educación",
    },
    {
      tab: "04. Inmobiliarias",
      title: "Mensajes masivos por WhatsApp para Inmobiliarias, Bienes y Raíces",
      text: (
        <p>
          <br />
          Mantén a los clientes potenciales informados sobre nuevas propiedades en el mercado, cambios de precios y días de puertas abiertas. Segmente tus mensajes y envía información relevante según las preferencias y necesidades de cada cliente, como ubicación deseada o rango de precios.
          <br /> <br /><br />
        </p>
      ),
      image: inmobiliaria,
      link: "/hablemos/",
      textLink: "Conoce WhatsApp masivo para Inmobiliarias",
    },
    {
      tab: "05. Salud",
      title: "Mensajes masivos por WhatsApp para Clínicas, Hospitales y Centros de Salud",
      text: (
        <p>
          <br />
          Facilita los recordatorios de citas, consejos de salud personalizados y alertas de vacunación. Además, puede ser una herramienta eficaz para campañas de concientización sobre temas de salud pública, contribuyendo al bienestar general.
          <br /> <br /><br />
        </p>
      ),
      image: salud,
      link: "/hablemos/",
      textLink: "Conoce WhatsApp masivo para Salud",
    },
    {
      tab: "06. Concesionarias",
      title: "Mensajes masivos por WhatsApp para Concesionarias",
      text: (
        <p>
          <br />
          Envía recordatorios de mantenimiento, ofertas especiales en servicios o accesorios y lanzamientos de nuevos modelos. Personaliza estos mensajes basándose en el historial de servicios y preferencias del cliente puede incrementar las visitas al taller y fomentar la lealtad hacia la marca.
          <br /> <br /><br />
        </p>
      ),
      image: concesionaria,
      link: "/hablemos/",
      textLink: "Conoce WhatsApp masivo para Concesionarias",
    },

  ],
}

export default data
